/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// Import CSS files
import './src/css/apartment-detail.css'; 
import './src/css/mobile-fixes.css'; // Mobile-specific fixes 

// Scroll to top when route changes, but preserve hash links functionality
export const onRouteUpdate = ({ location }) => {
  if (typeof window !== 'undefined') {
    // Only scroll to top if there's no hash in the URL
    // This preserves normal menu anchor links functionality
    if (!location.hash) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, 0);
    }
  }
}; 